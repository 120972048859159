import axios from "../http-common";
import loggerService from "./logger";

export default {
    getVariactions(payLoad, url) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/${url[0]}/${url[1]}`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getProducts(payLoad, url, keyword) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/${url[0]}/${url[1]}/${keyword}`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getCucCodes(keyword, url) {
        const payLoad = {
            emailId: url[2],
            search: keyword.trim(),
            status: url[3] === "" ? null : url[3]
        };
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/${url[0]}/${
                url[1]
            }`, payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getDefault(url) {
        return axios
            .get(`${process.env.VUE_APP_API_URL}/${url}`)
            .catch((error) => {
                loggerService.logError(error);
            });
    }
}