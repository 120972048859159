var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c(
        "div",
        { staticClass: "control" },
        [
          _vm.isAutoFetch
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchText,
                    expression: "searchText"
                  }
                ],
                ref: "dropdown",
                staticClass: "input-dropdown",
                attrs: {
                  type: "text",
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  title: _vm.placeholder
                },
                domProps: { value: _vm.searchText },
                on: {
                  blur: function($event) {
                    _vm.isOpened = false
                  },
                  focus: function($event) {
                    _vm.isOpened = true
                  },
                  keyup: function($event) {
                    return _vm.selectOnKeys($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchText = $event.target.value
                  }
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search"
                  }
                ],
                ref: "dropdown",
                staticClass: "input-dropdown",
                attrs: {
                  type: "text",
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled
                },
                domProps: { value: _vm.search },
                on: {
                  blur: function($event) {
                    _vm.isOpened = false
                  },
                  focus: function($event) {
                    _vm.isOpened = true
                  },
                  keydown: _vm.selectOnKeys,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  }
                }
              }),
          _c("Loader", {
            attrs: {
              loading: _vm.isEditLoading,
              size: _vm.size,
              color: _vm.color
            }
          }),
          !_vm.disabled
            ? _c("div", { staticClass: "search-icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 20 20"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                      }
                    })
                  ]
                )
              ])
            : _vm._e(),
          (_vm.search
          ? false
          : !_vm.isAutoFetch)
            ? _c("div", {
                staticClass: "arrow-icon",
                class: { active: _vm.isOpened },
                on: { click: _vm.toggle }
              })
            : _vm._e(),
          _vm.selectedOptions.length >= 1
            ? _c("div", { staticClass: "count-label" }, [
                _vm._v(
                  " (" + _vm._s(_vm.selectedOptions.length) + ") selected "
                )
              ])
            : _vm._e(),
          (_vm.search
          ? true
          : false)
            ? _c(
                "div",
                { staticClass: "clear-icon", on: { click: _vm.clear } },
                [!_vm.disabled ? _c("span", [_vm._v("×")]) : _vm._e()]
              )
            : _vm._e()
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade", mode: "in-out" } }, [
        _vm.isApiLoading && _vm.isOpened && !_vm.multiple
          ? _c(
              "ul",
              { ref: "optionsList", staticClass: "options-list" },
              [
                _vm.searchText.length < _vm.limit
                  ? _c("li", [
                      _vm._v(" Type minimum 3 letters to get options ")
                    ])
                  : _vm.isLoading
                  ? _c("Loader", { attrs: { loading: _vm.isLoading } })
                  : _vm.apiResponseOptions.length === 0
                  ? _c("li", { staticClass: "no-data" }, [
                      _vm._v(" No data found ")
                    ])
                  : _vm._e(),
                _vm._l(_vm.apiResponseOptions, function(option, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      ref: "optionsListItem",
                      refInFor: true,
                      class: { selected: i === _vm.selected },
                      on: {
                        mouseenter: function($event) {
                          _vm.selected = i
                        },
                        mousedown: function($event) {
                          return _vm.select()
                        }
                      }
                    },
                    [
                      option.Info === "Delisted"
                        ? _c("span", { staticClass: "delisted" }, [
                            _vm._v(_vm._s(option.Value))
                          ])
                        : _c("span", [_vm._v(_vm._s(option.Value))])
                    ]
                  )
                })
              ],
              2
            )
          : _vm.isOpened && !_vm.multiple
          ? _c(
              "ul",
              { ref: "optionsList", staticClass: "options-list" },
              [
                _vm.filteredItems.length === 0
                  ? _c("li", { staticClass: "no-data" }, [
                      _vm._v(" No data found. ")
                    ])
                  : _vm._e(),
                _vm._l(_vm.filteredItems, function(option, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      class: { selected: i === _vm.selected },
                      on: {
                        mouseenter: function($event) {
                          _vm.selected = i
                        },
                        mousedown: function($event) {
                          return _vm.select()
                        }
                      }
                    },
                    [
                      option.Info === "Delisted"
                        ? _c("span", { staticClass: "delisted" }, [
                            _vm._v(_vm._s(option.Value))
                          ])
                        : _c("span", [_vm._v(_vm._s(option.Value))])
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e(),
        _vm.isOpened && _vm.multiple
          ? _c(
              "ul",
              { ref: "optionsList", staticClass: "options-list" },
              [
                _vm.filteredItems.length === 0
                  ? _c("li", { staticClass: "no-data" }, [
                      _vm._v(" No data found. ")
                    ])
                  : _vm._e(),
                _vm.filteredItems.length > 0
                  ? _c("li", { staticClass: "selectUnselect" }, [
                      _c(
                        "span",
                        {
                          on: {
                            mousedown: function($event) {
                              return _vm.toggleSelectAll()
                            }
                          }
                        },
                        [
                          _vm.toggleSelectAllText
                            ? _c("span", [_vm._v("select all")])
                            : _c("span", [_vm._v("unselect all")])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._l(_vm.filteredItems, function(option, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      class: { selected: option.isChecked },
                      on: {
                        mousedown: function($event) {
                          return _vm.multiSelect(option)
                        }
                      }
                    },
                    [
                      _c("Checkbox", {
                        attrs: {
                          checked: option.isChecked,
                          label: option.Value
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }