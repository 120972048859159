<template>
  <div class="autocomplete">
    <div class="control">
      <!--for auto fetch-->
      <input
        type="text"
        class="input-dropdown"
        v-bind:placeholder="placeholder"
        @blur="isOpened = false"
        @focus="isOpened = true"
        @keyup="selectOnKeys($event)"
        v-model="searchText"
        ref="dropdown"
        :disabled="disabled"
        v-if="isAutoFetch"
        :title="placeholder"
      />
      <!-- -->
      <input
        type="text"
        class="input-dropdown"
        v-bind:placeholder="placeholder"
        @blur="isOpened = false"
        @focus="isOpened = true"
        @keydown="selectOnKeys"
        v-model="search"
        ref="dropdown"
        :disabled="disabled"
        v-else
      />
      <Loader :loading="isEditLoading" :size="size" :color="color" />
      <div class="search-icon" v-if="!disabled">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
          />
        </svg>
      </div>
      <div
        class="arrow-icon"
        @click="toggle"
        :class="{ active: isOpened }"
        v-if="search ? false : !isAutoFetch"
      ></div>
      <div class="count-label" v-if="selectedOptions.length >= 1">
        ({{ selectedOptions.length }}) selected
      </div>
      <div class="clear-icon" @click="clear" v-if="search ? true : false">
        <span v-if="!disabled">&times;</span>
      </div>
    </div>

    <transition name="fade" mode="in-out">
      <!--Auto Fetch-->
      <ul
        class="options-list"
        ref="optionsList"
        v-if="isApiLoading && isOpened && !multiple"
      >
        <li v-if="searchText.length < limit">
          Type minimum 3 letters to get options
        </li>
        <Loader :loading="isLoading" v-else-if="isLoading" />
        <li v-else-if="apiResponseOptions.length === 0" class="no-data">
          No data found
        </li>
        <li
          ref="optionsListItem"
          v-for="(option, i) in apiResponseOptions"
          :key="i"
          @mouseenter="selected = i"
          @mousedown="select()"
          :class="{ selected: i === selected }"
        >
          <span class="delisted" v-if="option.Info === 'Delisted'">{{
            option.Value
          }}</span>
          <span v-else>{{ option.Value }}</span>
        </li>
      </ul>

      <!--else normal-->
      <ul
        class="options-list"
        ref="optionsList"
        v-else-if="isOpened && !multiple"
      >
        <li v-if="filteredItems.length === 0" class="no-data">
          No data found.
        </li>
        <li
          v-for="(option, i) in filteredItems"
          :key="i"
          @mouseenter="selected = i"
          @mousedown="select()"
          :class="{ selected: i === selected }"
        >
          <span class="delisted" v-if="option.Info === 'Delisted'">{{
            option.Value
          }}</span>
          <span v-else>{{ option.Value }}</span>
        </li>
      </ul>
      <!--Multiselect-->
      <ul class="options-list" ref="optionsList" v-if="isOpened && multiple">
        <li v-if="filteredItems.length === 0" class="no-data">
          No data found.
        </li>
        <li v-if="filteredItems.length > 0" class="selectUnselect">
          <span @mousedown="toggleSelectAll()">
            <span v-if="toggleSelectAllText">select all</span>
            <span v-else>unselect all</span>
          </span>
        </li>
        <li
          v-for="(option, i) in filteredItems"
          :key="i"
          @mousedown="multiSelect(option)"
          :class="{ selected: option.isChecked }"
        >
          <Checkbox :checked="option.isChecked" :label="option.Value" />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import authentication from "../../authentication";
import autoFetchService from "../../services/autofetch";
import loggerService from "../../services/logger";

export default {
  components: {
    Checkbox: () => import(/* webpackPrefetch: true */ "./Checkbox"),
    Loader: () => import(/* webpackPrefetch: true */ "./Loader"),
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isAutoFetch: {
      type: Boolean,
      default: false,
    },
    autoFetchType: {
      type: String,
    },
    apiPath: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isProductType: {
      type: String,
      default: "add",
    },
    placeholder: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 3,
    },
    isEditData: {
      default: "",
    },
  },
  data: () => ({
    isOpened: false,
    selected: null,
    search: "",
    apiResponseOptions: "",
    selectedOptions: [],
    isLoading: true,
    searchText: "",
    toggleSelectAllText: true,
    size: "5px",
    isEditLoading: false,
    color: "#cccccc",
  }),
  created() {
    this.populateOptions();
    document.addEventListener("click", this.handleClickOutside);
  },
  computed: {
    isApiLoading() {
      return this.apiPath === null ? false : true;
    },
    ...mapGetters({
      brandFromReports: "GET_BRAND_IN_REPORTS",
      countriesFromReports: "GET_COUNTRIES_IN_REPORTS",
    }),
    filteredItems() {
      const condition = new RegExp(
        this.search.length >= this.limit
          ? this.search.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
          : "",
        "i"
      );

      if (this.options.length !== 0) {
        return this.options.filter((item) => item.Value.match(condition));
      } else if (this.apiResponseOptions.length !== 0) {
        return this.apiResponseOptions.filter((item) =>
          item.Value.match(condition)
        );
      } else {
        return [];
      }
    },
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    setEditData() {
      if (this.multiple) {
        if (this.isEditData[0] !== 0) {
          this.isEditData.forEach((id) => {
            const fObeject = this.filteredItems.filter((item) => {
              return item.Id == id;
            });
            this.multiSelect(fObeject[0]);
          });
        }
      } else {
        const fObeject = this.filteredItems.filter((item) => {
          return item.Id == this.isEditData;
        });
        this.$emit("selected-item", fObeject[0]);
        this.search = fObeject[0].Value;
      }
    },
    toggleSelectAll() {
      this.toggleSelectAllText = !this.toggleSelectAllText;
      if (this.toggleSelectAllText) {
        this.filteredItems.map((option) => (option.isChecked = false));
        this.selectedOptions = [];
        this.$emit("selected-item", this.selectedOptions);
      } else {
        this.selectedOptions = [];
        this.filteredItems.map((option) => {
          option.isChecked = true;
          this.selectedOptions.push(option);
        });
        this.$emit("selected-item", this.selectedOptions);
      }
    },
    autoUp(searchText) {
      this.searchText = searchText;
      if (this.autoFetchType === "ProductReports") {
        this.autoFetchProducts();
      } else if (this.autoFetchType === "VariationReports") {
        this.autoFetchVariatoins();
      } else if (
        this.autoFetchType === "CucCodes" ||
        this.autoFetchType === "CucReports"
      ) {
        this.autoFetchCucCodes();
      }
    },
    select() {
      let selectedOption;
      if (this.isAutoFetch) {
        selectedOption = this.apiResponseOptions[this.selected];
      } else {
        selectedOption = this.filteredItems[this.selected];
      }
      this.$emit("selected-item", selectedOption);
      this.search = selectedOption.Value;
      this.searchText = selectedOption.Value;
      this.isOpened = false;
    },
    selectOnKeys(event) {
      switch (event.keyCode) {
        case 38:
          this.isOpened = true;
          if (this.selected === null) {
            this.selected = 0;
          } else if (this.selected > 0) {
            this.$refs.optionsList.scrollTop -= 25;
            this.selected--;
          }
          break;
        case 40:
          this.isOpened = true;
          if (this.isAutoFetch) {
            if (this.selected === null) {
              this.selected = 0;
            } else if (this.selected < this.apiResponseOptions.length - 1) {
              this.$refs.optionsList.scrollTop += 25;
              this.selected++;
            }
          } else {
            if (this.selected === null) {
              this.selected = 0;
            } else if (this.selected < this.filteredItems.length - 1) {
              this.$refs.optionsList.scrollTop += 25;
              this.selected++;
            }
          }
          break;
        case 13:
          if (this.selected === null) {
            this.selected = 0;
          }
          if (this.multiple) {
            this.multiSelect(this.filteredItems[this.selected]);
          } else {
            this.select();
          }
          break;
        default:
          if (this.isAutoFetch) {
            this.autoUp(event.target.value);
          } else {
            this.isOpened = true;
          }
      }
    },
    multiSelect(option) {
      if (this.isEditData) {
        this.isOpened = false;
      } else {
        this.isOpened = true;
      }

      option.isChecked = !option.isChecked;
      if (option.isChecked) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = this.selectedOptions.filter(
          (f) => f.Id != option.Id
        );
      }
      this.$emit("selected-item", this.selectedOptions);
      if (this.options.length == 0) {
        if (this.selectedOptions.length == this.apiResponseOptions.length) {
          this.toggleSelectAllText = false;
        } else {
          this.toggleSelectAllText = true;
        }
      } else {
        if (this.selectedOptions.length == this.options.length) {
          this.toggleSelectAllText = false;
        } else {
          this.toggleSelectAllText = true;
        }
      }
    },
    toggle() {
      this.isOpened = !this.isOpened;
      this.focus();
    },
    clear() {
      this.search = "";
      this.searchText = "";
      this.focus();
      if (this.multiple) {
        this.$emit("selected-item", this.selectedOptions);
      } else {
        this.$emit("selected-item", true);
      }
      if (this.isAutoFetch) {
        this.apiResponseOptions = [];
      }
    },
    focus() {
      this.$refs.dropdown.focus();
      this.isOpened = true;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpened = false;
      }
    },
    populateOptions() {
      if (this.filteredItems.length == 0) {
        if (this.apiPath != null) {
          this.fetchData();
        } else {
          this.apiResponseOptions = [];
        }
        if (this.apiResponseOptions.length === 1) {
          this.search = this.apiResponseOptions[0].Value;
          this.selected = 0;
          this.multiSelect();
        }
      } else if (this.options.length === 1) {
        this.search = this.options[0].Value;
        this.selected = 0;
        this.select();
      } else if (this.options.length > 1 && this.isProductType === "edit") {
        this.search = this.options[0].Value;
        this.selected = 0;
        this.select();
      }

      if (this.isEditData) {
        this.isEditLoading = true;
        setTimeout(() => {
          this.setEditData();
          this.isEditLoading = false;
        }, 2000);
      }
    },
    async fetchData() {
      if (this.isAutoFetch) {
        if (this.autoFetchType === "ProductReports") {
          this.autoFetchProducts();
        } else if (this.autoFetchType === "VariationReports") {
          this.autoFetchVariatoins();
        } else if (this.autoFetchType === "CucCodes") {
          this.autoFetchCucCodes();
        }
      } else {
        try {
          const { data } = await autoFetchService.getDefault(this.apiPath);

          this.isLoading = false;
          this.apiResponseOptions = data;
        } catch (error) {
          loggerService.logError(error);
          this.apiResponseOptions = [];
        }
      }
    },
    async autoFetchCucCodes() {
      if (this.searchText.length >= this.limit - 1) {
        this.isLoading = true;
        this.isOpened = !!this.searchText;
        this.apiResponseOptions = [];
        const autoApiPath = this.apiPath.split("/");
        // in nominal composiiton report  autoApiPath[2] as loggedin email key will be undefined
        if (
          this.autoFetchType === "CucReports" ||
          this.autoFetchType === "CucCodes"
        ) {
          try {
            const { data } = await autoFetchService.getCucCodes(
              this.searchText,
              autoApiPath
            );

            this.isLoading = false;
            this.apiResponseOptions = data;
          } catch (error) {
            loggerService.logError(error);
            this.apiResponseOptions = [];
          }
        }
      } else {
        this.apiResponseOptions = [];
      }
    },
    async autoFetchProducts() {
      if (this.searchText.length >= this.limit - 1) {
        this.isLoading = true;
        this.isOpened = !!this.searchText;
        this.apiResponseOptions = [];
        const autoApiPath = this.apiPath.split("/");
        let payLoad = {
          countries:
            this.countriesFromReports === "" ||
            this.countriesFromReports === null
              ? "Any"
              : this.countriesFromReports,
          brands:
            this.brandFromReports === "" || this.brandFromReports === null
              ? "Any"
              : this.brandFromReports,
          selectDelisted: true,
        };
        try {
          const { data } = await autoFetchService.getProducts(
            payLoad,
            autoApiPath,
            this.searchText
          );

          this.isLoading = false;
          this.apiResponseOptions = data;
        } catch (error) {
          loggerService.logError(error);
          this.apiResponseOptions = [];
        }
      } else {
        this.apiResponseOptions = [];
      }
    },
    async autoFetchVariatoins() {
      if (this.searchText.length >= this.limit - 1) {
        this.isLoading = true;
        this.isOpened = !!this.searchText;
        this.apiResponseOptions = [];
        const autoApiPath = this.apiPath.split("/");
        let payLoad = {
          emailId: authentication.getUserEmail(),
          countryId:
            this.countriesFromReports === "" ||
            this.countriesFromReports === null
              ? null
              : this.countriesFromReports,
          brandName:
            this.brandFromReports === "" || this.brandFromReports === null
              ? null
              : this.brandFromReports,
          productId:
            this.productId === "" || this.productId === null
              ? null
              : this.productId,
          selectDelisted: true,
          search: this.searchText,
        };
        try {
          const { data } = await autoFetchService.getVariactions(
            payLoad,
            autoApiPath
          );

          this.isLoading = false;
          this.apiResponseOptions = data;
        } catch (error) {
          loggerService.logError(error);
          this.apiResponseOptions = [];
        }
      } else {
        this.apiResponseOptions = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.control {
  position: relative;
  display: flex;
  margin-bottom: 0.1rem;
  input.input-dropdown {
    height: 30px;
    width: 100%;
    border: 1px solid #2196f3;
    border-radius: 3px;
    padding-right: 38px;
    padding-left: 20px;
    outline: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &[disabled] {
      padding-left: 5px;
      opacity: 0.8;
    }
  }
  input.input-dropdown::-webkit-input-placeholder {
    color: rgb(197, 191, 191);
    font-size: 0.9rem;
  }
  .arrow-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 1.25rem;
    bottom: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-right: 2px solid #888;
    border-bottom: 2px solid #888;
    transform: translateY(-2px) rotate(45deg);
    transition: transform 0.2s ease;
  }
  .arrow-icon.active {
    transform: translateY(2px) rotate(225deg);
  }
  .clear-icon {
    position: absolute;
    top: -1px;
    right: 1rem;
    font-size: 20px;
    color: #888;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .count-label {
    position: absolute;
    right: 2.8rem;
    top: 0.61rem;
  }
  .search-icon {
    position: absolute;
    top: 7px;
    left: 5px;
    svg {
      width: 12px;
      fill: #bbb;
    }
  }
}
ul.options-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 0;
  border: 1px solid #dbdbdb;
  border-radius: 0 0 3px 3px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 3px 6px 0px rgba(195, 195, 195, 0.76);
  position: absolute;
  width: 100%;
  z-index: 10;
  min-height: 50px;
  background-color: #fff;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    cursor: pointer;
    transition: 0.3s all ease;
    word-wrap: break-word;
    .delisted {
      color: #bbb;
    }
    &:hover {
      background-color: #2196f3;
      color: #fff;
      .chkbox {
        label {
          color: #fff !important;
        }
      }
    }
    span {
      margin-right: 4px;
    }
    &.selected {
      background-color: #2196f3;
      color: #fff;
      .chkbox {
        label {
          color: #fff !important;
        }
      }
    }
    &.no-data {
      background-color: #fff;
      color: #444;
      &:hover {
        background-color: #fff;
      }
    }
  }
  li:last-child {
    border-bottom: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  will-change: opacity;
  transform: translateY(20%);
}

.filters {
  div.control {
    input.input-dropdown {
      border: 1px solid #ccc;
    }
  }
}
.modal-body {
  div.control {
    input.input-dropdown {
      border: 1px solid #d2d6de;
    }
    .search-icon {
      top: 10px;
    }
  }
  ul.options-list {
    max-height: 150px;
    width: 98.5%;
  }
}
.product__info {
  ul.options-list {
    max-height: 150px;
  }
}
*::-ms-backdrop,
div.control .search-icon svg {
  height: 15px;
}
.selectUnselect {
  position: absolute;
  display: block;
  width: 100%;
  background-color: #fff;
  span {
    width: 100%;
    text-align: right;
  }
  &:hover {
    background-color: #fff !important;
    color: #1a7dcb !important;
    text-shadow: 0 0 black;
  }
}
.selectUnselect + li {
  margin-top: 30px;
}
.v-spinner {
  position: absolute;
  margin-left: 15px;
  margin-top: -2px;
}
</style>
